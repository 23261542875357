import logo from './assets/templogo.png';
import './App.css';

function App() {
  const currentYear = new Date().getFullYear(); // Получаем текущий год

  return (
    <div className="app">
        <header className="header"></header>
        <main className="main">
            <div className="infinity-path"></div> {/* Контейнер для анимации */}
            <div className="logo-container">
                <img src={logo} className="app-logo" alt="logo" />
                <p className="development-text">Game Outsourcing Studio</p>
            </div>
        </main>
        <footer className="footer" data-year={currentYear}>
            <div className="footer-left">
                <a href="mailto:info@silicon-valley-art.com">E-MAIL: info@silicon-valley-art.com</a>
                <a href="tel:+14252746756">PHONE: +1 425 274 6756</a>
            </div>
            <div className="footer-middle">
                <a href="https://www.linkedin.com/company/silicon-valley-art" target="_blank" rel="noopener noreferrer">
                    <div className="linkedin-icon">in</div>
                </a>
            </div>
            <div className="footer-right">
                {/* Место для содержимого правой части футера, если оно требуется */}
            </div>
        </footer>
    </div>
  );
}

export default App;
